import React from 'react'
import Logo from '../img/logo.png'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

export const Footer = () => {
  const { t } = useTranslation()
  return (
    <footer className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 grid justify-items-center">
      <div className="grid grid-flow-row-dense grid-cols-1 grid-rows-1 mt-10">

      </div>
      <div className="flex flex-wrap mt-6 text-sm text-slate-500 sm:mb-0 flex justify-center">
        <div className='mr-10 mb-5'>
          <a href="https://ruomi.io/" className="flex flex-wrap mt-6 text-sm text-slate-500 sm:mb-0 flex justify-center">
            <img src={Logo} className="h-7 mr-2" alt="Crazy Office Logo" />
            <span className="text-xm font-semibold">{t('footer.title')}</span>
          </a>
        </div>
        <button className="mr-4 hover:underline md:mr-6 hover:text-slate-600" onClick={() => i18n.changeLanguage('en')}>EN</button>
      </div>
      <hr className="my-6 border-slate-200 sm:mx-auto lg:my-8" />
      <span className="block text-sm text-slate-500 sm:text-center">© 2022 <a href="https://ruomi.io/" className="hover:underline hover:text-slate-600">Ruomi ITS</a>. {t('footer.rights')} {t('footer.cookie')}
      </span>
    </footer>
  )
}
