import React from 'react'
import img from '../img/me2.jpg'
import { useTranslation } from 'react-i18next'

export const Contact = () => {

  const { t } = useTranslation()

  return (
    <>
      <div className="relative px-6 lg:px-8">
        <div className="mx-auto max-w-3xl text-center sm:text-center mt-2">
          <div>
            <h1 className="font-bold tracking-tight sm:text-center mb-10 text-2xl">
              {t('contact.title')}
            </h1>
            <div className="shadow-sm">
              <div className="h-full flex sm:flex-row flex-col items-center justify-center text-center">
                <div className="flex-grow sm:p-8 text-center">
                  <p className="text-gray-900 hover:text-blue-600 mb-2"><a href='https://www.linkedin.com/in/dariahannonen/'>LinkedIn</a></p>
                  <p className="mb-4">contact@ruomi.io</p>
                  <p className="mb-4">+3584578338377</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
