import React from 'react'
import { Portfolio } from './Portfolio'
import { useTranslation } from 'react-i18next'
import Logo from '../img/kela.png'

export const PortfolioDevelopment = () => {

  const { t } = useTranslation()

  return (
    <>
      <Portfolio />
      <div className="relative px-6 lg:px-8">
        <div className="container px-5 py-10 mx-auto">
          <p className='flex justify-center'>{t('portfoliodev.title')}</p>

          <div className="container px-5 py-24 mx-auto flex flex-wrap">

            <div className="flex relative pt-10 pb-20 sm:items-center md:w-2/3 mx-auto">
              <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-0.5 bg-slate-200 pointer-events-none"></div>
              </div>
              <div className="flex-shrink-0 w-20 h-8  mt-10 sm:mt-0 inline-flex items-center justify-center bg-white relative z-10 title-font font-medium text-sm">2018 - 2021</div>
              <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                <div className="flex-shrink-0 w-24 h-24 rounded-md inline-flex items-center justify-center">
                  <img src={Logo} className="h-7 mr-2" alt="Crazy Office Logo" />
                </div>
                <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                  <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">{t('portfoliodev.f1.title')}</h2>
                  <p className="leading-relaxed">{t('portfoliodev.f1.desc1')}</p>
                  <p className="leading-relaxed">{t('portfoliodev.f1.desc2')}</p>
                </div>
              </div>
            </div>

            <div className="flex relative pt-10 pb-20 sm:items-center md:w-2/3 mx-auto">
              <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-0.5 bg-slate-200 pointer-events-none"></div>
              </div>
              <div className="flex-shrink-0 w-20 h-8  mt-10 sm:mt-0 inline-flex items-center justify-center bg-white relative z-10 title-font font-medium text-sm">2018 - 2021</div>
              <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                <div className="flex-shrink-0 w-24 h-24 rounded-md inline-flex items-center justify-center">
                  <img src={Logo} className="h-7 mr-2" alt="Crazy Office Logo" />
                </div>
                <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                  <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">{t('portfoliodev.f1.title')}</h2>
                  <p className="leading-relaxed">{t('portfoliodev.f1.desc1')}</p>
                  <p className="leading-relaxed">{t('portfoliodev.f1.desc2')}</p>
                </div>
              </div>
            </div>

            <div className="flex relative pt-10 pb-20 sm:items-center md:w-2/3 mx-auto">
              <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-0.5 bg-slate-200 pointer-events-none"></div>
              </div>
              <div className="flex-shrink-0 w-20 h-8  mt-10 sm:mt-0 inline-flex items-center justify-center bg-white relative z-10 title-font font-medium text-sm">2018 - 2021</div>
              <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                <div className="flex-shrink-0 w-24 h-24 rounded-md inline-flex items-center justify-center">
                  <img src={Logo} className="h-7 mr-2" alt="Crazy Office Logo" />
                </div>
                <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                  <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">{t('portfoliodev.f1.title')}</h2>
                  <p className="leading-relaxed">{t('portfoliodev.f1.desc1')}</p>
                  <p className="leading-relaxed">{t('portfoliodev.f1.desc2')}</p>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </>
  )
}