import React from 'react'
import { useTranslation } from 'react-i18next'
import img1 from '../img/comics/img1.png'
import img2 from '../img/comics/img2.png'
import img3 from '../img/comics/img3.png'
import img4 from '../img/comics/img4.png'
import img5 from '../img/comics/img5.png'

export const Comics = () => {

  const { t } = useTranslation()

  return (
    <>
      <div className="relative px-6 lg:px-8">
        <div className="mx-auto max-w-3xl text-center sm:text-center mt-2">
          <div className="relative px-6 lg:px-8">
            <div className="mx-auto max-w-3xl text-center sm:text-center mt-2">
              <div>
                <h2 className="font-bold tracking-tight sm:text-center mb-10 text-2xl">Maria in IT</h2>
                <div className="relative px-6 lg:px-8">
                  <div className="mx-auto max-w-3xl text-center sm:text-center mt-2">
                    <div>
                      <div className="grid grid-cols-1 gap-12 relative">
                        <div className="relative mb-8">
                          <img src={img1} alt="Первая картинка" />
                          <div className="absolute top-1/2 p-2 bg-white opacity-75 rounded text-black">
                        Привет, меня зовут Мария. Недавно я окончила школу с красным дипломом и отправилась в другую страну, чтобы следовать своей мечте. Сейчас я студентка университета, где учусь на факультете химии. Ура! У меня есть студенческая виза, я смогла получить общежитие и теперь живу в своем любимом городе - Хельсинки.
                          </div>
                        </div>
                        <div className="relative mb-8 mt-8">
                          <div className="relative top-8 right-8 p-2 bg-white opacity-75 rounded text-black">
                          Мария: Я знаю, что мне нужно как можно скорее начать работать и начать поддерживать своих родителей, а не просить у них или у тебя помощи. Но индустрия химии, по большей части, негативно влияет на окружающую среду, и мне всегда нравилось работать с техникой. Может быть, стоит попробовать себя в области информационных технологий? Но что, если я не справлюсь, и это займет гораздо больше времени...
                          </div>
                          <img src={img2} alt="Вторая картинка" />
                          <div className="relative bottom-8 left-8 p-2 bg-white opacity-75 rounded text-black">
                          Анна: Ты заколебала, хватит ныть. Делай уже то, что хочешь.
                          </div>
                        </div>
                        <div className="relative mb-8 mt-8 mb-8">
                          <img src={img3} alt="Третья картинка" />
                          <div className="relative bottom-12 p-2 bg-white opacity-75 rounded text-black">
                          Наконец-то я поступила на ИТ, и теперь я иду к своей мечте! Если я смогла поступить, то смогу и учиться и успешно закончить! Я особенная, здесь, среди всех этих отличников, я одна девушка. Теперь все будут удивляться и восхищаться мной. Вау, симпатичная девушка и айтишница!
                          </div>
                        </div>
                        <div className="relative mb-8 mt-8 mb-8">
                          <img src={img4} alt="Третья картинка" />
                          <div className="relative bottom-12 p-2 bg-white opacity-75 rounded text-black">
                          О боже, как же трудно разобраться во всем этом, особенно не на родном языке.
                          </div>
                        </div>
                        <div className="relative mb-8 mt-8">
                          <div className="relative top-8 right-8 p-2 bg-white opacity-75 rounded text-black">
                          Мария: Профессор, помогите мне, я совсем не понимаю это задание.
                          </div>
                          <img src={img5} alt="Вторая картинка" />
                          <div className="relative bottom-8 left-8 p-2 bg-white opacity-75 rounded text-black">
                          Профессор: Если ты не понимаешь, может быть, ты недостаточно умна? Может быть, эта профессия не для тебя? На лекции были даны все вводные данные для решения этого задания.
                          </div>
                        </div>
                        <div className="relative mb-8 mt-8 mb-8">
                          <div className="relative bottom-12 p-2 bg-white opacity-75 rounded text-black">
                          Мария училась днями и ночами, казалось, что это длится бесконечно. Всего обучение заняло у неё несколько лет. Иногда она находилась в полном недопонимании и депрессии, она боялась, что не справится. Она была совершенно одна и иногда уходила в полное забытье.
                          </div>
                          <p>Продолжение следует...</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
