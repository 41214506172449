import React from 'react'
import Logo from '../img/logo.png'
import { Link } from 'react-router-dom'
import Container from '../components/Container'
import { Disclosure } from '@headlessui/react'

export const Navbar = () => {
  return (
    <Container>
      <div>
        <Disclosure>
          {({ open }) => (
            <>
              <div className="flex flex-wrap justify-between md:gap-10 md:flex-nowrap">
                <div className="flex-col items-center justify-start order-1 hidden w-full md:flex md:flex-row md:justify-end md:w-auto md:order-none md:flex-1">
                  <Link to='/about' className="mr-4 hover:underline hover:text-slate-600 md:mr-6">
                    <a className="px-5 py-2 text-sm font-medium text-slate-600 hover:text-red-500">
											About
                    </a>
                  </Link>
                </div>
                <div className="flex justify-between w-full md:w-auto">
                  <Link to='/' className='flex items-center'>
                    <a className="flow-root">
                      <img src={Logo} alt="Ruomi Logo" className="h-8 sm:h-10 mr-2" />
                    </a>
                  </Link>
                  <Disclosure.Button
                    aria-label="Toggle Menu"
                    className="px-2 py-1 ml-auto text-slate-500 rounded-md md:hidden focus:text-red-400 focus:outline-none">
                    <svg
                      className="w-6 h-6 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24">
                      {open && (
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                        />
                      )}
                      {!open && (
                        <path
                          fillRule="evenodd"
                          d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                        />
                      )}
                    </svg>
                  </Disclosure.Button>
                </div>
                <div className="flex flex-col items-center justify-start order-2 hidden w-full md:flex md:flex-row md:w-auto md:flex-1 md:order-none">
                  <Link to='/contact' className="mr-4 hover:underline hover:text-slate-600 md:mr-6">
                    <a className="px-5 py-2 text-sm font-medium text-slate-600 hover:text-red-500">
											Contact
                    </a>
                  </Link>
                </div>
              </div>

              <Disclosure.Panel>
                <div className="items-center justify-center order-2 w-full md:hidden mt-4 mb-4 flex gap-4">
                  <Link to='/about' >
                    <a className="text-sm font-medium text-slate-600 hover:text-red-500">About</a>
                  </Link>
                  <Link to='/contact' >
                    <a className="text-sm font-medium text-slate-600 hover:text-red-500">Contact</a>
                  </Link>
                </div>
              </Disclosure.Panel>
              <hr className="my-2 border-slate-200 sm:mx-auto lg:my-2" />
            </>
          )}
        </Disclosure>
      </div>
    </Container>
  )
}
