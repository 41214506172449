import React from 'react'
import { Portfolio } from './Portfolio'
import { useTranslation } from 'react-i18next'

export const PortfolioUIUX = () => {

  const { t } = useTranslation()

  return (
    <>
      <Portfolio />
      <div className="relative px-6 lg:px-8">
        <div className="container px-5 py-10 mx-auto">
          <p className='py-10 flex justify-center'>{t('portfoliouiux.title')}</p>
        </div>
      </div>
    </>
  )
}