import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const Portfolio = () => {

  const { t } = useTranslation()

  return (
    <>
      <div className="relative px-6 lg:px-8">
        <div className="container px-5 mx-auto">
          <div className="flex flex-col text-center w-full mb-12">
            <h1 className="font-bold tracking-tight sm:text-center mb-10 text-2xl">{t('portfolio.title')}</h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base mb-10">{t('portfolio.desc1')}</p>
            <div className='grid justify-items-center mx-auto max-w-screen-md mb-4'>
              <div className="flex flex-wrap mb-0 text-sm text-slate-700 sm:mb-0 ">
                {(<Link to='/portfolio/development' className="mr-4 hover:underline hover:text-slate-600 md:mr-6">{t('portfolio.development')}</Link>)}
                {(<Link to='/portfolio/uiux' className="mr-4 hover:underline hover:text-slate-600 md:mr-6">{t('portfolio.uiux')}</Link>)}
                {(<Link to='/portfolio/scrum' className="mr-4 hover:underline hover:text-slate-600 md:mr-6">{t('portfolio.scrum')}</Link>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}