import React, { Fragment } from 'react'
import './index.css'
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom'
import { Home } from './pages/Home'
import { Navbar } from './components/Navbar'
import { Footer } from './components/Footer'
import { About } from './pages/About'
import { Contact } from './pages/Contact'
import { Portfolio } from './pages/Portfolio'
import { PortfolioDevelopment } from './pages/PortfolioDevelopment'
import { PortfolioUIUX } from './pages/PortfolioUIUX'
import { PortfolioScrum } from './pages/PortfolioScrum'
import { Comics } from './pages/Comics'

const Layout = () => {
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  )
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/about',
        element: <About />
      },
      {
        path: '/comics',
        element: <Comics />
      },
      {
        path: '/portfolio',
        element: <Portfolio />
      },
      {
        path: '/portfolio/development',
        element: <PortfolioDevelopment />
      },
      {
        path: '/portfolio/uiux',
        element: <PortfolioUIUX />
      },
      {
        path: '/portfolio/scrum',
        element: <PortfolioScrum />
      },
      {
        path: '/contact',
        element: <Contact />
      }
    ]
  },
])

function App() {

  return (
    <Fragment>
      <RouterProvider router={router} />
    </Fragment>

  )
}

export default App
