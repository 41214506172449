import React from 'react'
import { useTranslation } from 'react-i18next'

export const About = () => {

  const { t } = useTranslation()

  return (
    <>
      <div className="relative px-6 lg:px-8">
        <div className="mx-auto max-w-3xl text-center sm:text-center mt-2">
          <div>
            <div className="shadow-sm text-center">
              <div className="h-full flex sm:flex-row flex-col items-center sm:justify-center justify-center text-center">
                <div className="flex-grow sm:p-8">
                  <div className="flex gap-4 mb-4 items-center sm:justify-center justify-center text-center">
                    <a className="title-font font-medium text-lg text-slate-900 hover:text-red-600 text-center" href='https://dariahannonen.com/' target="_blank" rel="noreferrer">Daria Hannonen</a>
                    <a href="https://www.linkedin.com/in/dariahannonen" target="_blank" className="text-gray-400 hover:text-blue-500 active:text-gray-600 transition duration-100" rel="noreferrer">
                      <svg className="w-5 h-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                      </svg>
                    </a>
                  </div>
                  <p className="mb-4">{t('about.me1')}</p>
                  <p className="mb-4">{t('about.me2')}</p>
                  <p className="mb-4">{t('about.me3')}</p>
                  <div className="flex gap-4 items-center sm:justify-center justify-center text-center mb-4">
										Follow Ruomi ITS at LinkedIn and stay tuned for my adventures. I am open to new professional contacts and cooperation proposals.
                  </div>
                  <div className="h-full flex sm:flex-row flex-col items-center sm:justify-center justify-center text-center">
                    <a href="https://www.linkedin.com/company/ruomi/" target="_blank" className="text-gray-400 hover:text-blue-500 active:text-gray-600 transition duration-100" rel="noreferrer">
                      <svg className="w-5 h-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
